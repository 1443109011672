<div [ngClass]="CRMPartnerID==371?'bg-primary':'bg-dark'">
    <div class="mt--30">
        <div class="container">
            <div class="col-12 mb-3 text-white pt-5">
                <p class="fs-1 fw-bold">
                    Welcome to the 48-hour Flash Sale waiting room, take a seat while you wait for us to let you in. Remember to refresh your page periodically so that you don't miss your chance because slots are limited.
                </p>
                <p class="fs-1 fw-bold">
                    Next up, you'll need to fill in some of your details, so get them ready!
                </p>
                <div class="text-center py-5"><a class="btn btn-light rounded-pill fs-1 fw-bold px-5" style="color:rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;" href="/servicecampaign">Book Now</a></div>
            </div>
        </div>
        <app-slider [images]="banners"></app-slider>
    </div>
</div>