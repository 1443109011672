<div *ngIf="!loading" class="col-12 banner">
    <app-slider [images]="this.banners"></app-slider>
</div>
<div class="form">
    <div *ngIf="FinanceOverride">
        <div *ngFor="let item of Texts">
            <p>{{item.text}}</p>
        </div>
    </div>
    <div *ngIf="!FinanceOverride">
        <p class="">Buying a vehicle is exciting and we would like to make your experience easier by allowing you to apply for finance online.</p>
        <p class="">If you are 18 years or older, a {{!namibiaInfoOverride ? 'South African' : 'Namibian'}} citizen and have a valid driver’s licence, please fill in the form below to get started. </p>
    </div>
<form [formGroup]="formFinance">
    
    <div class="">
        <div class="">
            <div class="flex">
                <div class="selectbox">
                    <div class="select-radius">
                        <p>Dealership</p>
                        <select formControlName="dealer">
                            <option value="-1" selected disabled hidden>Dealership</option>
                            <optgroup *ngFor="let region of Regions" label="{{region.Name}}">
                                <option *ngFor="let dealer of regionDealers(region.Name)"
                                    value="{{dealer.DealershipID_PK}}">
                                    {{dealer.Name}}</option>
                            </optgroup>
                        </select>
                    </div>
                </div>

            </div>

        </div>
    </div>
    <div class="">
      
        <div class="">
            <div class="flex">
                <div class="selectbox short">
                    <div class="select-radius">
                        <p>Title</p>
                        <select formControlName="title">
                            <option value="-1" selected disabled hidden>Title</option>
                            <option *ngFor="let title of Titles" value="{{title.TitleID_PK}}">{{title.Title}}</option>
                        </select>
                    </div>
                </div>

                <div class="selectbox long">
                    <div class="select-radius">
                        <input formControlName="name" type="text" placeholder="Name">
                    </div>
                </div>
            </div>

            <div class="selectbox ">
                <div class="select-radius">
                    <input formControlName="surname" type="text" placeholder="Surname">
                </div>
            </div>
            <div class="selectbox ">
                <div class="select-radius">
                    <input formControlName="mail" type="text" placeholder="Email Address">
                </div>
            </div>
            <div class="selectbox">
                <div class="select-radius">
                    <input formControlName="phone" type="text" placeholder="Mobile Number">
                </div>
            </div>
            <div *ngIf="FinanceOverride" class="selectbox">
                <div class="select-radius">
                    <textarea rows="2" formControlName="notes" placeholder="Optional Comments"></textarea>
                </div>
            </div>
              
            <div class="col-12 market"><input type="checkbox" id="marketing" formControlName="marketing" name="marketing"
                value="true"><p> I agree to
            receive
            digital
            communication from {{siteName}}</p>
            </div>
            <ul *ngIf="this.formFinance.invalid && this.failedsubmit == true" class="requirements">
                <li *ngIf="this.formFinance.controls['dealer'].errors">Dealership is required.</li>
                <li *ngIf="this.formFinance.controls['name'].errors">First Name is required.</li>
                <li *ngIf="this.formFinance.controls['surname'].errors">Last Name is required.</li>
                <li *ngIf="this.formFinance.controls['mail'].errors">Valid Email is required.</li>
                <li *ngIf="this.formFinance.controls['phone'].errors">10+ digit Contact Number is required.</li>
            </ul>
            <div *ngIf="!FinanceOverride" class="cent"><button (click)="submit()" class="btn-primary">Start Finance Application</button></div>
            <div *ngIf="FinanceOverride" class="cent"><button (click)="submit()" class="btn-primary">Submit Enquiry</button></div>
        </div>
    </div>
</form>

<p class="disc">Disclaimer:<br/>
    * Terms & Conditions Apply. Subject to Finance Approval with your Bank.<br/>
    All calculations made on calculators supplied on this site, together with rates quoted, are guidelines only and are subject to confirmation at the time of finalising any transactions.<br/>
    All information regarding the products, fees and/or costs which are included in and form a fundamental basis of the calculations are subject to change at any time prior to a final pre-agreement quote being handed to the User.<br/>
    <span *ngIf="!namibiaInfoOverride">McCarthy (Pty) Ltd is an authorized Financial Services Provider. FSP6852.<br/></span>
    The User indemnifies {{!namibiaInfoOverride ? 'McCarthy.co.za' : 'Novel Motor Company'}} against any loss or liability, which the User may suffer as a result of the use of any calculator. The site and all information provided on this site and the services provided on this site, are provided "as is". The information provided on this site should not be treated as professional advice of any kind.</p>
</div>