import {
  Component,
  enableProdMode,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import { SitesService } from './services/sites.service';
import { take } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { filter } from 'rxjs';
import { NavigationEnd } from '@angular/router';

import { environment } from 'src/environments/environment';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { GearsApiService } from './services/gearsapi.service';
import { Inject } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { ModalService } from './modules/modalservice.service';
import { ChatserviceService } from './services/chatservice.service';
import { CampaignsService } from './services/campaigns.service';
declare global {
  interface Window {
    dataLayer: any[];
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent {
  homeMeta: any = {};
  currentSite: any = {};
  display = false;
  showCookies = true;
  currentSiteTopnav: any = {};
  currentSiteNavbar: any = {};
  currentSiteGlobal: any = {};
  hidefooter: boolean = false;
  hidetopnav: boolean = false;
  colorEnum = [
    'primary',
    'secondary',
    'tertiary',
    'quaternary',
    'quinary',
    'senary',
  ];
  namibiaInfoOverride: any;
  constructor(
    private modalService: ModalService,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private _document: Document,
    private chatService: ChatserviceService,
    private apiService: GearsApiService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private title: Title,
    private meta: Meta,
    private http: HttpClient,
    private siteservice: SitesService,
    private campaignsService: CampaignsService
  ) {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem('acceptedCookies')) {
        this.showCookies = false;
      } else {
        localStorage.setItem('acceptedCookies', 'true');
      }
      if (!sessionStorage.getItem('token')) {
        apiService.getAuth()
          .subscribe((x: any) => {
            sessionStorage.setItem('token', x.body.Data.AuthToken);
            this.loadsite();
          });
      } else {
        this.loadsite();
      }
    }
  }

  browser = true;

  loadsite() {
    this.siteservice.get().then((x: any) => {
      var icon = document.createElement('link');
      icon.setAttribute('rel', 'icon');
      icon.setAttribute('type', 'image/x-icon');
      icon.setAttribute(
        'href',
        'assets/icons/' + x.container.Name.replaceAll(' ', '') + '/favicon.ico?v=2'
      );
      document.head.appendChild(icon!);

      var iconapple = document.createElement('link');
      iconapple.setAttribute('rel', 'mask-icon');
      iconapple.setAttribute('type', 'image/x-icon');
      iconapple.setAttribute('color', '#000000');
      iconapple.setAttribute(
        'href',
        window.location.origin + '/assets/icons/' + x.container.Name.replaceAll(' ', '') + '/favicon.ico?v=2'
      );
      document.head.appendChild(iconapple!);

      var s = {
        name: x.Name,
        short_name: x.Name,
        theme_color: x.CssLogoText.split(',')[0],
        background_color: '#212529',
        display: 'standalone',
        scope:  window.location.protocol+'//' + window.location.host,
        start_url:  window.location.protocol+'//' + window.location.host,
        icons: [
          {
            src:
            window.location.protocol+'//' +
              window.location.host +
              '/assets/icons/' +
              x.container.Name.replaceAll(' ', '') +
              '/PWA_icon_72x72.png',
            sizes: '72x72',
            type: 'image/png',
            purpose: 'maskable any',
          },
          {
            src:
            window.location.protocol+'//' +
              window.location.host +
              '/assets/icons/' +
              x.container.Name.replaceAll(' ', '') +
              '/PWA_icon_96x96.png',
            sizes: '96x96',
            type: 'image/png',
            purpose: 'maskable any',
          },
          {
            src:
            window.location.protocol+'//' +
              window.location.host +
              '/assets/icons/' +
              x.container.Name.replaceAll(' ', '') +
              '/PWA_icon_128x128.png',
            sizes: '128x128',
            type: 'image/png',
            purpose: 'maskable any',
          },
          {
            src:
            window.location.protocol+'//' +
              window.location.host +
              '/assets/icons/' +
              x.container.Name.replaceAll(' ', '') +
              '/PWA_icon_144x144.png',
            sizes: '144x144',
            type: 'image/png',
            purpose: 'maskable any',
          },
          {
            src:
            window.location.protocol+'//' +
              window.location.host +
              '/assets/icons/' +
              x.container.Name.replaceAll(' ', '') +
              '/PWA_icon_152x152.png',
            sizes: '152x152',
            type: 'image/png',
            purpose: 'maskable any',
          },
          {
            src:
            window.location.protocol+'//' +
              window.location.host +
              '/assets/icons/' +
              x.container.Name.replaceAll(' ', '') +
              '/PWA_icon_192x192.png',
            sizes: '192x192',
            type: 'image/png',
            purpose: 'maskable any',
          },
          {
            src:
            window.location.protocol+'//' +
              window.location.host +
              '/assets/icons/' +
              x.container.Name.replaceAll(' ', '') +
              '/PWA_icon_384x384.png',
            sizes: '384x384',
            type: 'image/png',
            purpose: 'maskable any',
          },
          {
            src:
            window.location.protocol+'//' +
              window.location.host +
              '/assets/icons/' +
              x.container.Name.replaceAll(' ', '') +
              '/PWA_icon_512x512.png',
            sizes: '512x512',
            type: 'image/png',
            purpose: 'maskable any',
          },
        ],
      };

      const stringManifest = JSON.stringify(s);
      const blob = new Blob([stringManifest], { type: 'application/json' });
      const manifestURL = URL.createObjectURL(blob);

      const manifest = document.createElement('link');
      manifest.setAttribute('rel', 'manifest');
      //icon.setAttribute('type','image/x-icon');
      manifest.setAttribute('href', manifestURL);
      document.head.appendChild(manifest!);

      this.currentSite = x;
      if (this.currentSite.FontID_FK){
        const newStyle = document.createElement('style');
        newStyle.appendChild(document.createTextNode(`@font-face {font-family: user_selection;
          src: url('${environment.storageurl}Fonts/${this.currentSite.FontID_FK}_font.woff') format('woff'),
            url('${environment.storageurl}Fonts/${this.currentSite.FontID_FK}_font.eot') format('embedded-opentype'),
            url('${environment.storageurl}Fonts/${this.currentSite.FontID_FK}_font.ttf') format('truetype'),
            url('${environment.storageurl}Fonts/${this.currentSite.FontID_FK}_font.svg') format('svg')
          ;}
          body {font-family: "user_selection", system-ui, Roboto, "Helvetica Neue", sans-serif !important;}`));
        document.head.appendChild(newStyle);
        // System-ui is the default font used across all sites
      }
      
      this.currentSiteTopnav = this.currentSite?.layout.filter((j: any) => {
        if (j.PageLayoutModuleName == 'topnav') return j.PageLayoutType;
      });
      this.currentSiteNavbar = this.currentSite?.layout.filter((s: any) => {
        if (s.PageLayoutModuleName == 'navbar') return s.PageLayoutType;
      });

      this.currentSiteGlobal = this.currentSite?.layout.filter((s: any) => {
        if (s.PageLayoutModuleName == 'global') return s.PageLayoutType;
      });

      const bottomnav = this.currentSite?.layout.find((plm:any)=> plm.PageLayoutModuleName === "bottomnav")?.PageLayoutType;
      this.hidefooter = bottomnav.some((plt:any)=> plt.PageLayoutTypeName === "hidefooter");
      this.hidetopnav = this.currentSiteTopnav[0]?.PageLayoutType?.some((plt:any)=> plt.PageLayoutTypeName === "hidetopnav");
      
      this.namibiaInfoOverride = this.currentSite.layout
        .filter((s:any) =>{if (s.PageLayoutModuleName == 'features')return s.PageLayoutType})[0]?.PageLayoutType
        ?.find((data:any) => data.PageLayoutTypeName == 'namibiainfooverride');
      
      
      var styles: any[] = [];
      for (let i = 0; i < x.CssLogoText.split(',').length; i++){
        styles.push({name: '--'+this.colorEnum[i], value: x.CssLogoText.split(',')[i]})
      }
      if(this.currentSiteGlobal[0]?.PageLayoutType.filter((y:any) => {return y.PageLayoutTypeName == 'buttonSecondary'})[0]?.Value){
        var buttonstyle = document.createElement('style');
        buttonstyle.innerText = '.btn-primary{background-color:var(--secondary) !important;border-color:var(--secondary) !important; color:white !important}.btn-secondary{color:var(--secondary) !important}';

      document.head.appendChild(buttonstyle!);
        
      }
      if (styles) {
        styles.forEach((data) => {
          document.documentElement.style.setProperty(
            `${data.name}`,
            data.value
          );
        });
      }
    });
  }

  AcceptCookineNotice() {
    window.document.getElementById('cookies')!.style.display = 'none';
  }

  color = 'blue';
  ngOnInit(): void {
    var pagemodule = document.location.pathname.split('/').join('');

    pagemodule = pagemodule == '' ? 'home' : pagemodule;
    pagemodule = pagemodule == 'service' ? 'servicebookings' : pagemodule;
    pagemodule = pagemodule == 'parts' ? 'serviceandparts' : pagemodule;

    pagemodule = pagemodule.includes('buystock')
      ? 'preownedvehicles'
      : pagemodule;
    pagemodule = pagemodule.includes('buynew') ? 'newvehicles' : pagemodule;

    pagemodule =
      pagemodule == 'buy' &&
      (document.location.search.includes('used') ||
        document.location.search.includes('demo'))
        ? 'preownedvehicles'
        : pagemodule;
    pagemodule =
      pagemodule == 'buy' && document.location.search.includes('new')
        ? 'newvehicles'
        : pagemodule;

    this.siteservice.get().then((x: any) => {
      var sitefeatures = x.layout.find(
        (PageLayoutModule: any) =>
          PageLayoutModule.PageLayoutModuleName == 'features'
      );
      var exitPopup = sitefeatures?.PageLayoutType.find(
        (feature: any) => feature.PageLayoutTypeName == 'exitPopup'
      );
      if (exitPopup) {
        var exitPopupImage = exitPopup.Value;
        //Desktop
        document.body.addEventListener(
          'mouseleave',
          (e) => this.showExitIntentModal(exitPopupImage),
          { once: true }
        );
        //Mobile
        document.body.addEventListener(
          'touchmove',
          (e) => this.showExitIntentModal(exitPopupImage),
          { once: true }
        );
      }
      var styles = [];
      for (let i = 0; i < x.CssLogoText.split(',').length; i++) {
        styles.push({
          name: this.colorEnum[i],
          value: x.CssLogoText.split(',')[i],
        });
      }
      if (styles) {
        styles.forEach((data) => {
          document.documentElement.style.setProperty(
            `--${data.name}`,
            data.value
          );
        });
      }
    });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.siteservice.get().then((x: any) => {
          this.meta.updateTag({ name: 'og:image', content: x.Images[0] || '' });
          var rt = this.getChild(this.activeRoute);

          rt.data.subscribe((data: any) => {
            this.title.setTitle(data.Title);
            this.display = true;
            setTimeout(
              () => {
                this.chatService.loadChat(pagemodule);
                this.apiService
                  .get('SiteTrackingCodes', {
                    Active: 1,
                    Deleted: 0,
                    PageNumber: 1,
                    PageSize: 9999,
                    OrderBy: '',
                    SiteID_FK: x.SiteID_PK,
                  })
                  .then((chat: any) => {
                    /* let gaScript = document.createElement('script');
                  gaScript.setAttribute(name, value);
                  gaScript.setAttribute(
                    'src',
                    'https://www.googletagmanager.com/gtag/js?id=UA-31960736-1'
                  );

                  let gaScript2 = document.createElement('script');
                  gaScript2.innerText =
                    '(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({"gtm.start":\nnew Date().getTime(),event:"gtm.js"});var f=d.getElementsByTagName(s)[0],\nj=d.createElement(s),dl=l!="dataLayer"?"&l="+l:"";j.async=true;j.src=\n"https://www.googletagmanager.com/gtm.js?id="+i+dl;f.parentNode.insertBefore(j,f);\n})(window,document,"script","dataLayer","GTM-MVF3FJX");';

                  document.head.appendChild(gaScript);
                  document.head.appendChild(gaScript2);*/

                    if (chat.body.length > 0) {
                      chat.body.forEach((token: any) => {
                        var snippet = JSON.parse(token.Snippet);
                        var Tokens = JSON.parse(token.Tokens);
                        var Values = JSON.parse(token.TokenValues);
                        snippet.forEach((trackscript: any) => {
                          var gaScript: HTMLElement;
                          if (trackscript.noscript) {
                            Tokens.forEach((element: any) => {
                              trackscript.noscript =
                                trackscript.noscript.replaceAll(
                                  '$' + element + '$',
                                  Values[element]
                                );
                            });

                            gaScript = document.createElement('noscript');
                            gaScript.innerHTML = trackscript.noscript;
                          } else if (trackscript.script) {
                            Tokens.forEach((element: any) => {
                              trackscript.script =
                                trackscript.script.replaceAll(
                                  '$' + element + '$',
                                  Values[element]
                                );
                            });
                            gaScript = document.createElement('script');
                            gaScript.innerText = trackscript.script;
                          } else {
                            gaScript = document.createElement('script');
                          }

                          if (trackscript.attributes) {
                            trackscript.attributes.forEach((attr: any) => {
                              Tokens.forEach((element: any) => {
                                gaScript.setAttribute(
                                  attr.name,
                                  attr.value.replaceAll(
                                    '$' + element + '$',
                                    Values[element]
                                  )
                                );
                              });
                            });
                          }

                          switch (token.SectionCode) {
                            case 'HEAD_TOP': {
                              document.head.appendChild(gaScript!);
                              //document.head.innerHTML += snippet;
                              //document.head.insertAdjacentElement('afterbegin', gaScript);
                              break;
                            }
                            case 'HEAD_BOTTOM': {
                              document.head.appendChild(gaScript!);
                              //document.head.innerHTML += snippet;
                              //document.head.insertAdjacentElement('beforeend', gaScript);
                              break;
                            }
                            case 'BODY_TOP': {
                              document.body.appendChild(gaScript!);
                              //document.body.innerHTML += snippet;
                              //document.body.insertAdjacentElement('afterbegin', gaScript);
                              break;
                            }
                            case 'BODY_BOTTOM': {
                              document.body.appendChild(gaScript!);
                              //document.body.innerHTML += snippet;
                              //document.body.insertAdjacentElement('beforeend', gaScript);
                              break;
                            }
                          }
                        });
                      });
                    }
                    window.dataLayer?.push({
                      event: 'TRACKING_LOADED',
                      event_category: 'STARTUP',
                      event_label: 'Tracking snippets loaded',
                      value: 'Tracking snippets loaded',
                    });
                  });
              },
              location.pathname.includes('thankyou') ? 0 : 2000
            );
            if (data.Module) {
              this.apiService
                .get('StateMeta', {
                  SiteID_FK: x.SiteID_PK,
                  Module: data.Module,
                  Active: 1,
                  Deleted: 0,
                  OrderBy: '',
                  PageSize: 1,
                  PageNumber: 1,
                })
                .then((stateMeta: any) => {
                  if (stateMeta.body.length > 0) {
                    stateMeta = stateMeta.body[0];
                    this.title.setTitle(stateMeta.Title || data.Module);
                    this.meta.updateTag({
                      name: 'keywords',
                      content: stateMeta.Keywords,
                    });
                    this.meta.updateTag({
                      name: 'description',
                      content: stateMeta.Description,
                    });
                  }
                });
            }
          });
        });
      });
    this.campaignsService.saveGoogleTracking();
  }

  showExitIntentModal(imageUrl: string) {
    var data = {
      Name: 'Contact Us',
      Dealer: null,
      Type: 'ContactUs',
      isExitIntent: true,
      ExitIntentImage: imageUrl,
    };
    if (!this.isExitIntentShownToday()) {
      var date = new Date();
      // add a day
      date.setDate(date.getDate() + 1);
      var exitIntentValue = {
        Expiry: date,
      };
      localStorage.setItem('exitIntent', JSON.stringify(exitIntentValue));
      this.modalService.open('enquire', data);
    }
  }

  isExitIntentShownToday() {
    var exitIntent = localStorage.getItem('exitIntent');
    if (exitIntent) {
      if (Date.now() < Date.parse(JSON.parse(exitIntent).Expiry)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
    return false;
  }
  tcs() {
    this.modalService.open('tcs');
  }
  getChild(route: ActivatedRoute): any {
    if (route.firstChild) {
      return this.getChild(route.firstChild);
    } else {
      return route;
    }
  }
}
