import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from 'src/app/modules/modalservice.service';
import { GearsApiService } from 'src/app/services/gearsapi.service';
import { SitesService } from 'src/app/services/sites.service';
import { VehicleMakesService } from 'src/app/services/vehicle-makes.service';
import { environment } from 'src/environments/environment';
import { CampaignsService } from 'src/app/services/campaigns.service';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.less'],
})
export class ServiceComponent implements OnInit {
  loading: boolean = true;
  datepickerMessage: string = '';
  availableBookingDates:any;
  customer: any = {};
  submitError = false;
  Regions: any = [];
  FilteredDealers: any[] = [];
  Dealerships: any = [];
  DealershipID_PK: any;
  banners: any[] = [];
  serviceForm!: FormGroup;
  formEnquire!:FormGroup;
  vehicleForm!:FormGroup;
  alternativeVehicleForm!: FormGroup;
  vehicle:any = {};
  dealer: any = null;
  stage: any = 0;
  Makes!: any[];
  Series!: any[];
  Years!: any[];
  Models!: any[];
  ModelsOfYear!:any[];
  evolvecode!: any;
  failedsubmit = false;
  Titles!:any[];
  dateselected:any;
  confirmationDate: any;
  altConfirmationDate: any;
  content: any = {};
  serviceText: any;
  ServiceIntervalsList = [
    '10,000 kms',
    '15,000 kms',
    '20,000 kms',
    '30,000 kms',
    '40,000 kms',
    '45,000 kms',
    '50,000 kms',
    '60,000 kms',
    '70,000 kms',
    '75,000 kms',
    '80,000 kms',
    '90,000 kms',
    '100,000 kms',
    '105,000 kms',
    '110,000 kms',
    '120,000 kms',
    '130,000 kms',
    '135,000 kms',
    '140,000 kms',
    '150,000 kms',
    'Other'
];

AlternateServiceIntervalsList = [
  '15,000 kms',
  '30,000 kms',
  '45,000 kms',
  '60,000 kms',
  '75,000 kms',
  '90,000 kms',
  '105,000 kms',
  '120,000 kms',
  '135,000 kms',
  '150,000 kms',
  'Other'
];
  rooftopid: any;
  rooftopToken: any;
  RecommandedService: any;
  isAlternativeService: boolean = false;
  minDate = new Date();
  maxDate = new Date();
  bookingDateError: boolean = false;
  altdateselected: Date[] = [];

  constructor(
    private modalService: ModalService,
    private makesService: VehicleMakesService,
    private apiservice: GearsApiService,
    private siteService: SitesService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private campaignService: CampaignsService
  ) {}
  siteid: any = 0;
  site: any;
  ServiceList: any;
  callback() {
    var data = { Type: 'callback', Name: 'Request call back' };
    this.modalService.open('enquire', data);
  }
  ngOnInit(): void {
    this.loading = true;
    this.route.queryParams.subscribe((params) => {
      this.dealer = params['dealership'];
      this.DealershipID_PK = this.dealer;
    });
    this.serviceForm = this.formBuilder.group({
      dealer: [null, Validators.min(0)],
      region: [null],
    });
    this.vehicleForm = this.formBuilder.group({
      odo: [null, Validators.required],
      reg: [null, Validators.required],
      make:[-1,Validators.min(0)],
      series:[-1,Validators.min(0)],
      year:[-1,Validators.min(0)],
      inter:[-1,Validators.min(0)],
      model:[-1,Validators.min(0)]
    });

    this.formEnquire = this.formBuilder.group({
      /* dealer: [-1, Validators.min(0)], */
      title: [null],
      region: [null],
      name: [null, Validators.required],
      surname: [null, Validators.required],
      email: [null, [Validators.required, Validators.email, Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)]],
      phone: [null, [Validators.required, Validators.pattern(/^(?:[\+][0-9])?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4}$/)]],
      marketing: [false],
      notes: [null],
      tcs: [false, Validators.requiredTrue]
    });

    this.alternativeVehicleForm = this.formBuilder.group({
      odometer: [null, Validators.required],
      registration: [null, Validators.required],
      interval:[-1,Validators.min(0)],
      modelname:[-1,Validators.min(0)]
    })

    this.siteService.get().then((x: any) => {
      this.siteid = x.SiteID_PK;
      this.site = x;
      const featureLayout = this.site.layout.filter((s:any) =>{if (s.PageLayoutModuleName == 'features')return s.PageLayoutType})[0]?.PageLayoutType;
      this.ServiceList = featureLayout?.find((data:any) => data.PageLayoutTypeName == 'servicelisting');
      this.isAlternativeService = this.site.layoutGlobal[0]?.PageLayoutType.find((x:any) => x.PageLayoutTypeName == 'AlternativeService') ? true : false;
      if (!this.isAlternativeService)this.isAlternativeService = this.site.layoutGlobal[0]?.PageLayoutType.find((x:any) => x.PageLayoutTypeName == 'AlternativeDetails') ? true : false;
      this.serviceText = this.site.layout
        .filter((s:any) =>{if (s.PageLayoutModuleName == 'home')return s.PageLayoutType})[0]?.PageLayoutType
        ?.find((data:any) => data.PageLayoutTypeName == 'servicetext');
      this.loading = false;
      this.apiservice
        .get(
          'Dealerships',
          {
            PageSize: 9999,
            PageNumber: 1,
            OrderBy: 'Name.ASC',
            SiteID_FK: this.siteid,
          },
          true
        )
        .then((x: any) => {
          this.Dealerships = x.body.filter((d:any) => d.ServiceDealer == true);
          this.FilteredDealers = this.Dealerships;
          if(this.Dealerships.length == 1){
            this.serviceForm.controls['dealer'].setValue(this.Dealerships[0].DealershipID_PK);
          }
          if (this.dealer !== null && this.dealer) {
            this.serviceForm.controls['dealer'].setValue(this.dealer);
            if (
              this.Dealerships.find(
                (x: any) =>
                  x.DealershipID_PK == this.serviceForm.controls['dealer'].value
              ).ServiceOnlineLink
            ) {
              window.open(
                this.Dealerships.find(
                  (x: any) =>
                    x.DealershipID_PK == this.serviceForm.controls['dealer'].value
                ).ServiceOnlineLink,
                '_blank'
              );
              window.location.href = '/';
            } else {
              
              this.evolvecode = this.Dealerships.find(
                (x: any) => x.DealershipID_PK == this.serviceForm.controls['dealer'].value
              ).EvolveInterfaceCode;
              this.makesService.getSiteMakes().then((x: any) => {
                this.Makes = x;
                this.stage = 1;
              });
            }
          }
          this.Dealerships.forEach((x: any) => {
            if (!this.Regions.includes(x.Region)) this.Regions.push(x.Region);
          });
          this.Regions.sort();
        });
      this.apiservice.get('Content', {
        SiteID_FK: this.siteid,
        ModuleCode: 'parts and service',
        PageSize: 9999,
        PageNumber: 1,
        OrderBy: ''
      }).then((x: any) => {
        this.content = x.body[0];
      });
      this.apiservice
        .get('Banners', {
          SiteID_FK: this.siteid,
          Module: 'Book a Service',
          PageSize: 9999,
          PageNumber: 1,
          OrderBy: '',
        })
        .then((x: any) => {
          this.banners = x.body;
        });
    });
  }
  getAppointmentDates(evolvecode: any) {
    this.loading = true;
    this.datepickerMessage = 'Retrieving available dates';
    let date = new Date(Date.now());
    let formatDate = date.toLocaleDateString('en-GB');
    let post = {Request: {
        Action : [
                {
              Method: "INH_AvailableBookingDates",
              MessageCreator: "McCarthy",
              MessageCreationDate: formatDate,
              MessageTrackingIdentifier: "272da8e0-a760-445b-a046-c93edaaf7389",
              InterfaceCode: evolvecode
            }
        ],
          AvailableBookingDates: [
            {
                NumberOfDays: 20
            }]
    }};
    this.apiservice.post('EvolveServiceOnline/AvailableBookingDates', post)
      .then((x:any) => {
        this.availableBookingDates = x.Response.AvailableDates;
        this.datepickerMessage = 'Choose a date';
        this.loading = false;
      })
  }


  async getRecommendedService(){
    this.loading = true;
    try{
    await this.apiservice.post('ServiceOnline/RecommendedService?makeCode='+this.Makes[0].MakeCode+'&modelCode='+this.vehicle.series+'&registrationNo='+this.vehicle.RegistrationNumber+'&rooftopId='+this.rooftopid+'&token='+this.rooftopToken+'&variantCode='+this.vehicle.VariantCode, null)
    .then((x:any) => {
      this.RecommandedService = x.Results.PriceListData[0];
      this.apiservice.post('ServiceOnline/AppointmentDates?JobCode='+this.RecommandedService.JobCode+'&makeCode='+this.Makes[0].MakeCode+'&modelCode='+this.vehicle.series+'&registrationNo='+this.vehicle.RegistrationNumber+'&rooftopId='+this.rooftopid+'&token='+this.rooftopToken+'&variantCode='+this.vehicle.VariantCode,null)
      .then((x:any)=>{
        this.availableBookingDates = x.WorksDiary;
        this.loading = false;
      })
    })}
    catch{
      this.loading = false;
    }
  }

  availableBookingDateFilter = (d: Date | null): boolean => {
    let date = (d || new Date());
    date.setDate(date.getDate()+1);
    if (this.availableBookingDates){
      if (!this.evolvecode){
        return this.availableBookingDates.some((item:any) => item == date.toISOString().slice(0, 10));
      }
      else if (this.evolvecode){
        return this.availableBookingDates.some((item:any) => item.Date == date.toISOString().slice(0, 10));
      }
      else {
        return false;
      }
    }
    else{
      return false;
    }
  };

  regionDealers(region: any) {
    return this.Dealerships.filter((x: any) => x.Region == region);
  }
  async service() {
    if (
      this.Dealerships.find(
        (x: any) =>
          x.DealershipID_PK == this.serviceForm.controls['dealer'].value
      ).ServiceOnlineLink
    ) {
      window.open(
        this.Dealerships.find(
          (x: any) =>
            x.DealershipID_PK == this.serviceForm.controls['dealer'].value
        ).ServiceOnlineLink,
        '_blank'
      );
      window.location.href = '/';
    } 
    else if (this.isAlternativeService) {
      this.DealershipID_PK = this.serviceForm.controls['dealer'].value
      this.stage = 1;
    }
    else {
      this.DealershipID_PK = this.serviceForm.controls['dealer'].value
      this.evolvecode = this.Dealerships.find(
        (x: any) => x.DealershipID_PK == this.serviceForm.controls['dealer'].value
      ).EvolveInterfaceCode;
      this.rooftopid = this.Dealerships.find(
        (x: any) => x.DealershipID_PK == this.serviceForm.controls['dealer'].value
      ).RoofTopID;
      if (this.rooftopid) {
        await this.apiservice
        .post('ServiceOnline/RequestToken', null)
        .then(async (x: any) => {
          this.rooftopToken = x.Token;
          await this.apiservice.post('ServiceOnline/ActivateToken?token='+this.rooftopToken,null)
          .then(async (x:any) => {
            await this.apiservice.post('ServiceOnline/CheckPassword?rooftopId='+this.rooftopid+'&token='+this.rooftopToken, null);
          })
        });
      }
      this.makesService.getSiteMakes().then(async (x: any) => {
        if (!this.evolvecode){
          await this.apiservice.post('ServiceOnline/Makes?makes=true&models=false&rooftopId='+this.rooftopid+'&token='+this.rooftopToken, null)
          .then((y:any) => {
            x[0].MakeCode= y.Results.MakeModelData.find((m:any)=> m.MakeName.toLowerCase() == x[0].Name.toLowerCase())?.MakeCode;
          })
        }
        this.Makes = x;
        this.stage = 1;
      });
    }
  }

  regionSelected() {
    this.FilteredDealers = this.Dealerships.filter((x: any) => {
      return x.Region == this.serviceForm.controls['region'].value;
    });
  }

  makeSelected(e: any) {
    if (this.evolvecode){
      this.apiservice
        .get('EvolveServiceOnline/GetSeries', {
          interfaceCode: this.evolvecode,
          make: e.target.value,
          
        })
        .then((x: any) => {
          this.vehicle.make = e.target.value;
          this.Series = x.body.Response.Series;
        });
    }
    else if (this.rooftopid){
      this.apiservice.post('ServiceOnline/Models?makeCode='+this.Makes[0].MakeCode+'&rooftopId='+this.rooftopid+'&token='+this.rooftopToken,null)
      .then((m:any) => {
        this.vehicle.make = e.target.value;
        this.Series = m.Results.MakeModelData;
      })
    }
  }
  seriesSelected(e: any) {
    if (this.evolvecode){
      this.apiservice
        .get('EvolveServiceOnline/GetModels', {
          interfaceCode: this.evolvecode,
          make: this.vehicle.make,
          series: e.target.value,
        })
        .then((x: any) => {
          this.vehicle.series = e.target.value;
          this.Models = x.body.Response.Models;
          this.Years = [];
          this.Models.forEach((x:any)=>{
            if(!this.Years.includes(x.Year)){
              this.Years.push(x.Year);
            }
          })
          this.Years.sort();
        });
    }
    else if (this.rooftopid){
      this.apiservice.post('ServiceOnline/Variants?makeCode='+this.Makes[0].MakeCode+'&modelCode='+e.target.value+'&rooftopId='+this.rooftopid+'&token='+this.rooftopToken,null)
      .then((v:any) => {
        this.vehicle.series = e.target.value;
        this.Models = v.Results.MakeModelData;
        this.Years = [];
        this.Models.forEach((x:any)=>{
          if(!this.Years.includes(x.ModelYear)){
            this.Years.push(x.ModelYear);
          }
        })
        this.Years.sort();
      });
    }
  }
  yearSelected(e: any) {
    if (this.evolvecode){
      this.ModelsOfYear = this.Models.filter((x:any)=>x.Year == e.target.value);
    }
    else if (!this.evolvecode){
      this.ModelsOfYear = this.Models.filter((x:any)=>x.ModelYear == e.target.value);
    }
    this.vehicle.year = e.target.value;
  }
  modelSelected(e:any){
    if (this.evolvecode){
      this.vehicle.mmcode = e.target.value;
      this.vehicle.modelDescription = e.target.selectedOptions[0].innerText;
    }
    else if (this.rooftopid){
      this.vehicle.VariantCode = e.target.value;
      this.vehicle.VariantDescription = e.target.selectedOptions[0].innerText;
    }
  }

  intervalSelected(e:any){
    this.vehicle.serviceinterval = e.target.value;
  }

  dateSelected(e:any, alt = false){
    let matDate = new Date(e)
    if (alt){
      this.vehicle.AltJobDate = new Date(e);
      this.altdateselected[1] = matDate;
      this.altConfirmationDate = e.toLocaleString('en-GB', { weekday: 'short' }) +' '+  e.getDate()
                            +' '+ e.toLocaleString('en-GB', { month: 'short' }) +' '+ e.getFullYear();
    }
    else{
      this.vehicle.JobDate = new Date(e);
      this.altdateselected[0] = new Date(matDate);
      this.dateselected = new Date(matDate.setDate(matDate.getDate() + 1))
      
      this.confirmationDate = e.toLocaleString('en-GB', { weekday: 'short' }) +' '+  e.getDate()
                            +' '+ e.toLocaleString('en-GB', { month: 'short' }) +' '+ e.getFullYear();
    }
    if(this.isAlternativeService){!(this.altdateselected[0] > this.altdateselected[1]) && !(this.altdateselected[0] < this.altdateselected[1]) ? this.bookingDateError = true : this.bookingDateError = false};
  }

  titleSelected(e:any){
    this.customer.Title = e.target[(e.target.value)].innerText;
  }

  nextstep(){
    if (this.isAlternativeService){
      if (this.alternativeVehicleForm.invalid){
        this.failedsubmit = true;
      }
      else{
        this.failedsubmit = false;
      }
      let date = new Date(Date.now());
      this.minDate.setDate(date.getDate());
      this.maxDate.setDate(date.getDate() + 30);
    }
    else {
      if (this.evolvecode){
        this.getAppointmentDates(this.evolvecode);
      }
      else if (this.rooftopid){
        this.getRecommendedService();
      }
    }
    this.apiservice.get('Titles', {
      PageSize: 9999,
      PageNumber: 1,
      OrderBy: ''
    }).then((x: any) => {
      this.Titles = x.body;
    });
    if (!this.failedsubmit){
      this.stage = 2;
    }
    
  }

  submit(){
    if (this.formEnquire.invalid || !this.vehicle.JobDate
      || this.vehicle.JobDate == null || this.bookingDateError) {
      this.failedsubmit = true;
    }
    else {
      this.loading = true;
      if (this.isAlternativeService){
        this.createServiceBookingEnquiry();
      }
      else if (this.evolvecode){
        this.createEvolveServiceBooking(this.evolvecode);
      }
      else if (this.rooftopid){
        this.createServiceBooking();
      }
    }
  }
  createServiceBookingEnquiry() {
    this.loading = true;
    this.apiservice.get('EnquiryTypes',{
      Active: 1,
      Deleted: 0,
      Code: 'ServiceBooking',
      OrderBy: 'Name.ASC',
      PageNumber: 1,
      PageSize: 9999
    }).then((enquirytype:any) => {
        let data = {
          Active: 1,
          Deleted: 0,
          Name: this.customer.FirstName,
          Surname: this.customer.Surname,
          SiteID_FK: this.site.SiteID_PK,
          Marketing: this.formEnquire.controls['marketing'].value,
          Notes: this.customer.Comments ?? '',
          DateBooking: this.vehicle.JobDate,
          SecondDateBooking: this.vehicle.AltJobDate,
          EnquiryTypeID_FK: enquirytype.body[0].EnquiryTypeID_PK,
          DealershipID_FK: this.DealershipID_PK,
          VehicleModel: this.vehicle.ModelName,
          RegNo: this.vehicle.RegistrationNumber,
          ServiceRequired: this.vehicle.serviceinterval,
          FullName: this.customer.FirstName + ' ' + this.customer.Surname,
          Title: this.customer.Title,
          Email: this.customer.EmailAddress,
          ContactNo: this.customer.ContactNo,
          UtmCampaign: this.campaignService.getGoogleTracking(),
          LinkURL: window.location.href,
          DataSourceID_FK: this.Dealerships[0].DataSourceID_FK,
          FacebookFBP: this.getCookie('_fbp'),
          FacebookFBC: this.getCookie('_fbc'),
          FacebookFBE: 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c){
            var r = (Math.random() * 16)| 0,
            v = c === 'x' ? r: (r & 0x3) | 0x8;
            return v.toString(16);
          }),
        }
        try{
          this.apiservice.post('Enquiries', data).then((x: any) => {
            if (x.EnquiryID_PK !== null) {
              window.location.href = '/service/thankyou';
            }
            else{
              this.submitError = true;
              this.loading = false;
            }
          });
        }
        catch (e){
          this.submitError = true;
          this.loading = false;
        }
      }
    );
  }
  getCookie(name: any) {
    var value = `; ${document.cookie}`;
    var parts!: any;
    parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    else return null;
  }

  getEvolveInterfaceCode(){
    return this.evolvecode;
  }

  createServiceBooking(){
    let data = {
      RooftopID: this.rooftopid,
      FirstName: this.customer.FirstName,
      SurName: this.customer.Surname,
      VehMakeCode: this.Makes[0].MakeCode,
      VehModelCode: this.vehicle.series,
      VehVariantCode: this.vehicle.VariantCode,
      VehicleRegistrationNo: this.vehicle.RegistrationNumber,
      ActualMileage: this.vehicle.Odometer,
      LastRegisteredDate: '',
      Jobs: [{
          JobCode: this.RecommandedService.JobCode,
          JobDescription: this.RecommandedService.JobDescription,
          JobComments: this.customer.Comments,
          JobPrice: this.RecommandedService.JobPrice,
          ParentJobCode: this.RecommandedService.ParentJobCode,
          VHCId: '',
          ProductCode: this.RecommandedService.ProductCode
      }],
      JobDate: this.vehicle.JobDate,
      TransportMethod: '',
      AdvisorID: '',
      AdvisorDropOffTimeCode: '',
      AdvisorPickUpTimeCode: '',
      TransAddress1: '',
      TransAddress2: '',
      TransAddress3: '',
      TransAddress4: '',
      TransAddress5: '',
      TransAddress6: '',
      TransAddress7: '',
      TransAddress8: '',
      TransAddress9: '',
      TransAddress10: '',
      TransAddress11: '',
      TransAddress12: '',
      TransAddress13: '',
      TransAddress14: '',
      TransAddress15: '',
      TransAddress16: '',
      TransPostalCode: '',
      SecondTransAddress1: '',
      SecondTransAddress2: '',
      SecondTransAddress3: '',
      SecondTransAddress4: '',
      SecondTransAddress5: '',
      SecondTransAddress6: '',
      SecondTransAddress7: '',
      SecondTransAddress8: '',
      SecondTransAddress9: '',
      SecondTransAddress10: '',
      SecondTransAddress11: '',
      SecondTransAddress12: '',
      SecondTransAddress13: '',
      SecondTransAddress14: '',
      SecondTransAddress15: '',
      SecondTransAddress16: '',
      SecondTransPostalCode: '',
      VehicleChassisNo: '',
      TransmissionCode: this.vehicle.TransmissionCode,
      TransmissionDesc: '',
      FuelCode: this.vehicle.FuelCode,
      FuelDesc: '',
      EngineSize: 0,
      VehColour: '',
      VehTrim: '',
      VehFreeDesc: '',
      Language: 'en-US',
      SendConfirmationMail: true,
      SetAsPreferredRoofTopId: false,
      CustomerId: null,
      Title: this.customer.Title,
      Address1: '',
      Address2: '',
      Address3: '',
      Address4: '',
      Address5: '',
      PostalCode: '',
      HomeTelNoAreaCode: '',
      HomeTelNo: '',
      MobileTelNo: this.customer.ContactNo,
      WorkTelNoAreaCode: '',
      WorkTelNo: '',
      WorkTelNoExt: '',
      EmailAddress: this.customer.EmailAddress,
      RepeatRepair: 0,
      IsFixItNowAppointment: 0,
      Driver: {
          Title: this.customer.Title,
          FirstName: this.customer.FirstName,
          SurName: this.customer.SurName,
          Email: this.customer.EmailAddress,
          Phone: this.customer.ContactNo
      },
      PreferredContactMethod: 0

    };
    this.apiservice.post('ServiceOnlineBooking/BookService?token='+this.rooftopToken,data)
    .then((x:any) => {
        window.location.href = '/service/thankyou';
    })
  }

  tcs() {
    this.modalService.open('tcs');
  }

  createEvolveServiceBooking(interfaceCode:any) {
    let date = new Date(Date.now());
    let formatDate = date.toLocaleDateString('en-GB');
    let data = {
        ServiceData: {
          Request:{
            Action: [
                {
                  Method: "INH_MaintenanceBooking",
                  MessageCreator: "McCarthy",
                  MessageCreationDate: formatDate,
                  MessageTrackingIdentifier: "272da8e0-a760-445b-a046-c93edaaf7389",
                  InterfaceCode: interfaceCode
                }
              ],
            Customer: [
              {
                ServiceRepair: "S",
                Title: this.customer.Title,
                LastName: this.customer.Surname,
                FirstName: this.customer.FirstName,
                Initial: this.customer.FirstName[0],
                CellNumber: this.customer.ContactNo,
                Email: this.customer.EmailAddress
              }
            ],
            Vehicle: [
              {
                RegistrationNumber: this.vehicle.RegistrationNumber,
                MmCode: this.vehicle.mmcode,
                Make: this.vehicle.make,
                Year: this.vehicle.year,
                OdoIn: this.vehicle.Odometer,
                AppointmentDate: this.vehicle.JobDate,
                DropOff: this.vehicle.DropOff
              }
            ],
            Job: [
              {
                CustomerStates: this.vehicle.Odometer + "km Service"
              }
            ]}},
        EnquiryData:{
          SiteID_PK : this.site.SiteID_PK,
          DealershipID_PK : this.DealershipID_PK,
          Marketing : this.formEnquire.controls['marketing'].value,
          ModelDescription : (this.vehicle.series+ ' ' + this.vehicle.modelDescription).toString().trim(),
          CustomerNote : this.formEnquire.controls['notes'].value
        }
    };
    this.apiservice.post('EvolveServiceOnline/MaintenanceBooking', data)
      .then((x:any) => {
          if (x.Response.Action.Status == "OK"){
            window.location.href = '/service/thankyou';
          }
          else{
            this.submitError = true;
            this.loading = false;
          }
      });
}
}