<div  *ngIf="isOpen" class="alertsmodal">
    <div class="card">
        <div class="card-header">
            Terms and Conditions
            <a (click)="close()"><h4><span class="icon-cross"></span></h4></a>
        </div>
        <div class="card-body">     
                <div class="row">
                    <div *ngIf="termsOfUse" class="col-12 mb-4">
                        <app-content [Text]="termsOfUse.HTML"></app-content>
                    </div>
                </div>
            <button (click)="close()" class="btn-primary col-3">Close</button>
        </div>
    </div>
</div>

