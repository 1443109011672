<div *ngIf="currentSite" class="root" >
    <h1 hidden>{{homeMeta.Title}}</h1>
    <h2 hidden>{{homeMeta.Description}}</h2>
    <app-nav-bar *ngIf="display && !hidetopnav"  [layout]="currentSiteTopnav" [navbar]="currentSiteNavbar" ></app-nav-bar>
    <router-outlet ></router-outlet>
    <app-bottom-nav *ngIf="display && !hidefooter"></app-bottom-nav>
    <app-enquiremodule [id]="'enquire'" style="display: none;"></app-enquiremodule>
    <app-finance [id]="'finance'" style="display: none;"></app-finance>
    <app-alerts [id]="'alerts'" style="display: none;"></app-alerts>
    <app-tcs [id]="'tcs'" style="display: none;"></app-tcs>
    <p  *ngIf="display && !namibiaInfoOverride && !hidefooter">A Juristic Representative of McCarthy (Pty) Ltd - FSP 6852 | <a
            href="https://www.bidvest.co.za/access-to-information.php" target="_blank">Access to Information</a> | <a
            href="https://www.bidvest.co.za/data-protection.php" target="_blank">Data Protection</a> | <a
            href="https://www.bidvest.co.za/group-governance.php" target="_blank">Group Governance</a> | Our Code of
        Ethics & Conflict of Interest policies are available on request | <a
            href="/assets/documents/INFORMATIONOFFICER.pdf" target="_blank">Information Officer</a> | <a class="fake-link-bot" 
            (click)="tcs()">Terms and Conditions</a></p>


    <div *ngIf="display && showCookies" id="cookies">
        <div class="row"
            style="background-color: #f2f2f2f2; position:fixed;bottom:0;z-index: 1000;padding:23px;left:15px;width:100%;">
            <div class="col-xs-12 col-sm-8 offset-sm-2 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                <strong>COOKIES NOTICE</strong><br>
                This website stores cookies on your computer. These cookies are used to improve your experience and
                provide more personalized services to you, both on this website and through other media. To find out
                more about the cookies we use, <a href="cookiepolicy">see our cookie policy.</a>
            </div>
            <div class="col-xs-12 col-sm-1">
                <button type="button" class="btn-primary" style="margin-top:5px;"
                    (click)="AcceptCookineNotice()">Close</button>
            </div>
        </div>
    </div>
</div>