import { NgClass } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { SitesService } from 'src/app/services/sites.service';
import { SliderModule } from "../slider/slider.component";
import { GearsApiService } from 'src/app/services/gearsapi.service';

@Component({
  selector: 'app-servicecampaign',
  standalone: true,
  imports: [NgClass, SliderModule],
  templateUrl: './servicecampaign.component.html',
  styleUrls: ['./servicecampaign.component.less']
})
export class ServicecampaignComponent implements OnInit {
  constructor(
    private siteService: SitesService,
    private api:GearsApiService
  ) {}
  CRMPartnerID:number = 0;
  banners:any;
  ngOnInit(): void {
    this.siteService.get().then((x: any) => {
      const currentSite = x;
      this.CRMPartnerID = currentSite.layoutGlobal[0]?.PageLayoutType.find((plt:any)=> plt.PageLayoutTypeName === "crmpartnerid")?.Value;
      this.api.get('Banners', {
        SiteID_FK: currentSite.SiteID_PK,
        Module: 'Service And Parts - Side',
        PageSize: 9999,
        PageNumber: 1,
        OrderBy: 'Order.ASC',
        Active: 1,
        Deleted: 0,
        OnlyCurrentBanners: true
      }).then((x:any)=>{
        if (x.ok){
          this.banners = x.body;
        }
      });
    });
  }
}
