import { Component, OnInit } from '@angular/core';
import { GearsApiService } from 'src/app/services/gearsapi.service';
import { SitesService } from 'src/app/services/sites.service';



@Component({
  selector: 'app-bottom-nav',
  templateUrl: './bottom-nav.component.html',
  styleUrls: ['./bottom-nav.component.less']
})
export class BottomNavComponent implements OnInit {

  constructor(private apiService: GearsApiService, private siteService: SitesService) { }
  Dealerships: any[] = [];
  Regions: any[] = [];
  Site:any;
  loading: boolean = true;
  flowdown: any;
  footerVehicleOption = [
    {"name":"new","text":"New", "url": "/buy?type=new"},
    {"name":"used","text":"Used", "url": "/buy?type=used"},
    {"name":"sell","text":"Sell My Car", "url": "/sell"},
    {"name":"service","text":"Book A Service", "url": "/service"},
    {"name":"parts","text":"Parts", "url":"/parts"},
    {"name":"news","text":"News", "url": "/news"}];
  ngOnInit(): void {
    this.siteService.get().then((x: any) => {
      this.Site = x;
      // if (this.Site.dealerSite == false)
      this.footerVehicleOption.push({"name":"whyservice", "text": "Why Service with " + this.Site?.container?.Name, "url": "/whyservice"});
      this.flowdown = (this.Site.layout
        .filter((s:any) =>{if (s.PageLayoutModuleName == 'bottomnav')return s.PageLayoutType}))[0].PageLayoutType
        .find((data:any) => data.PageLayoutTypeName == 'flowdown')
        ? 'flow-down' : '';
        var footerVehicle = (this.Site.layout
          .filter((s:any) =>{if (s.PageLayoutModuleName == 'bottomnav')return s.PageLayoutType}))[0].PageLayoutType
          .filter((data:any) => data.PageLayoutTypeName == 'footerVehicleOption')[0]?.Value;
        this.footerVehicleOption = footerVehicle ? footerVehicle : this.footerVehicleOption;
        this.footerVehicleOption = this.footerVehicleOption.filter((y:any) => !(y.name == 'specials' && this.Site.NumberOfSpecials <= 0));
        this.Site.noClubBanner = this.Site.layout
          .filter((s:any) =>{if (s.PageLayoutModuleName == 'bottomnav')return s.PageLayoutType})[0]?.PageLayoutType
          ?.find((data:any) => data.PageLayoutTypeName == 'noclubbanner')?.Value;
      this.apiService.get('Dealerships', {
        PageSize: 9999,
        PageNumber: 1,
        OrderBy: 'Name.ASC',
        SiteID_FK: x.SiteID_PK
      }, false).then((x: any) => {
        this.Dealerships = x.body;
        this.Dealerships.forEach(dealer => {
          if (!this.Regions.includes(dealer.Region)) {
            this.Regions.push(dealer.Region);
          }
        });
        this.Regions.sort();
        this.loading = false;
      });
    });
  }
  regionDealers(region: string) {
    return this.Dealerships.filter(x => { return x.Region == region })
  }

  checkWhyService(options:any){
    return options.some((x:any)=> x.name == 'whyservice');
  }
}
